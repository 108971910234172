$(document).ready(function(){
    window.pixelLabs = {};
    var primary;
    // calculate primaryColor
    primary = $("#primary-color").css("color");
    window.pixelLabs.primaryColor = primary;

    var secondary;
    // calculate secondaryColor
    secondary = $("#secondary-color").css("color");
    window.pixelLabs.secondaryColor = secondary;

    // calculate calculate primaryColor
    var tertiary = $("#tertiary-color").css("color");
    window.pixelLabs.tertiaryColor = tertiary;

    var navPixMin = $("#js-min-nav-height").outerHeight();
    window.pixelLabs.navHeightMin = navPixMin;
    var navPixMax = $("#js-max-nav-height").outerHeight();
    window.pixelLabs.navHeightMax = navPixMax;
    var navWinkMin = $("#js-min-height-wink").outerHeight();
    window.pixelLabs.winkHeightbegin = navWinkMin;
    window.pixelLabs.scrollPosition = $(window).scrollTop();

    window.pixelLabs.windowHeight = $(window).height();

    window.pixelLabs.windowWidth = $(window).width();

    if (window.pixelLabs.windowWidth < 769){
        window.pixelLabs.jumpButtonCorrection = 0;
    } else {
        window.pixelLabs.jumpButtonCorrection = window.pixelLabs.navHeightMin;
    }

    $(window).smartresize(function(){
        navigationHeight = $("#top").outerHeight();
        window.pixelLabs.navHeight = navigationHeight;
        window.pixelLabs.windowWidth = windowWidth;
        window.pixelLabs.windowHeight = $(window).height();
        window.pixelLabs.mainHeight = $("#main").outerHeight();
        window.pixelLabs.navHeight = $("#top").outerHeight();
    });

    $(window).bind('scroll',function () {
        scrollPosition = $(window).scrollTop();
        window.pixelLabs.scrollPosition = scrollPosition;
        window.pixelLabs.navHeight = $("#top").outerHeight();
    });
});


(function($,sr){
    // debouncing function from John Hann
    // http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
    var debounce = function (func, threshold, execAsap) {
        var timeout;
        return function debounced () {
            var obj = this, args = arguments;
            function delayed () {
                if (!execAsap)
                    func.apply(obj, args);
                timeout = null;
            };
            if (timeout)
                clearTimeout(timeout);
            else if (execAsap)
                func.apply(obj, args);
            timeout = setTimeout(delayed, threshold || 100);
        };
    }
    // smartresize
    jQuery.fn[sr] = function(fn){  return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr); };
})(jQuery,'smartresize');
// usage:

Modernizr.addTest('isios', function(){
    return navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ? true : false
});

//usage
if (Modernizr.isios) {
    $('body').addClass('ios');
}

function iOSversion() {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
        // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
        var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
        return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
    }
}
